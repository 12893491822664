import React from 'react'
import PropTypes from 'prop-types'
import { debounce } from 'lodash'
import { GOOGLE_ADVERSE_QUERY_SELECTOR } from '../utils/constants';

export class HTMLContent extends React.Component {
  constructor(props) {
    super(props)
    this.elemRef = React.createRef()
    // debounce will prevent frequent rendering while user is updating the content
    this.deboucedProcess = debounce(this.processContent, props.delay || 1000, { leading: true, trailing: true })
  }

  disableAds() {
    if (!(this.elemRef && this.elemRef.current)) return;

    this.elemRef.current
      .querySelectorAll(GOOGLE_ADVERSE_QUERY_SELECTOR)
      .forEach(elem => elem.parentNode.removeChild(elem));
  }
  processContent() {
    // fetch all child script elements and iterate over them for re-initialization
    if(!(this.elemRef && this.elemRef.current)) {
      return ;
    }

    // in cms we have increased delay, could cause this.elemRef to be undefined
    Array.from(this.elemRef.current.getElementsByTagName('script')).forEach(elem => {
      // script will have either attributes or the inline script
      if (elem.innerText || elem.hasAttributes()) {

        // clone script element
        let newScriptElem = document.createElement('script')
        newScriptElem.innerHTML = elem.innerHTML
        Array.from(elem.attributes).forEach(attr => {
          newScriptElem.setAttribute(attr.name, attr.value)
        })

        // insert activated script before the actual node
        elem.parentNode.insertBefore(newScriptElem, elem)

        // this will remove the non-activated script
        elem.parentNode.removeChild(elem)
      }
    })
  }
  componentDidMount() {
    !this.props.adsense && this.disableAds(); //if adsense isn't enabled, filter anchors in content
    if(this.props.loadScripts) this.deboucedProcess()
  }
  componentWillMount() {
    !this.props.adsense && this.disableAds(); //if adsense isn't enabled, filter anchors in content
    if (this.props.loadScripts) this.deboucedProcess()
  }

  componentDidUpdate() {
    (typeof this.props.adsense !== 'undefined' && !this.props.adsense) && this.disableAds();
    if(this.props.loadScripts) this.deboucedProcess()
  }
  render() {
    // when loaded in CMS it provides ReactElement instead of parsed HTML
    if (this.props.isCMS) {
      return <div ref={this.elemRef}>{this.props.content}</div>
    }

    return (
      <div
        ref={this.elemRef}
        className={this.props.className}
        dangerouslySetInnerHTML={{ __html: this.props.content }}
      />
    )
  }
}

const Content = ({ content, className }) => (
  <div className={className}>{content}</div>
)

Content.propTypes = {
  content: PropTypes.node,
  className: PropTypes.string,
}

HTMLContent.propTypes = {
  content: PropTypes.node,
  className: PropTypes.string,
  isCMS: PropTypes.bool, // if component is rendering for preview
  loadScripts: PropTypes.bool, // prevents unnecessary html processing
}

export default Content
